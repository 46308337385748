
import Listitem from './casestudy/listitem.vue';
import ListItemOther from './casestudy/listitem_other.vue';
import ListitemDownload from './casestudy/listitem_download.vue';
import ListitemExternal from './casestudy/listitem_external.vue';


export default {
    name: "caselisting",
    props: {
        content: Object
    },
    components: { Listitem, ListItemOther, ListitemDownload, ListitemExternal }
}


